<template>
  <div>
    <div class="container">
       <div class="header">
        <img src="../assets/icon30.png" alt="">
        <span>企业舆情</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/yuzhishubanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">企业负面舆情指数</div>
        <div class="txt2">980 <span>分</span></div>
        <div class="model1">
          <div class="title">企业舆情</div>
          <div class="list">
            <div class="item">
              <div>
                <img src="../assets/qiyejinyingicon@2x.png" alt="">
                <div class="txt">企业经营</div>
              </div>
              <div class="pos">0</div>
            </div>
            <div class="item">
              <div>
                <img src="../assets/qiyesifaicon@2x.png" alt="">
                <div class="txt">企业司法</div>
              </div>
              <div class="pos">0</div>
            </div>
            <div class="item">
              <div>
                <img src="../assets/xinwenyuqing-icon@2x.png" alt="">
                <div class="txt">新闻舆情</div>
              </div>
              <div class="pos">0</div>
            </div>
             <div class="item">
              <div>
                <img src="../assets/xingzhengchufa-icon@2x.png" alt="">
                <div class="txt">行政处罚</div>
              </div>
              <div class="pos">0</div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;border-radius: 5rem; ">上一页</div>  
    </div>
  </div>
</template>
<script>
 
export default {
  methods:{
    goPrev(){
      this.$router.push("/health")
    },
    goNext(){
      this.$router.push("/popularFeeling")
    }
  }
}
</script>
<style lang="scss" scoped>
 .list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top:2.5rem;
  width: 100%;
  .item{
    width: 46%;
    text-align: center;
    margin-bottom:4rem;
    position: relative;
    .pos{
      position: absolute;
      top:-1rem;
      font-size:1.7rem;
      right:0;
    }
    img{
      width: 5rem;
      background: #E7EAFF;
      border-radius: 31px;
    }
    .txt{
      width:100%;
      padding:0 1rem;
      height: 2.5rem;
      line-height:2.5rem;
      background: #E7EAFF;
      border-radius: 1.5rem;
      margin-top:0.5rem;
    }
  }
 }
</style>